import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title:'科技 · 让城市治理更简单高效！',
    pageCount:8,
    selected:0,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
